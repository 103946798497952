<template>
  <div class="">
    <!-- <div class=" px-2.5 py-4 bg-white" v-if="JSON.stringify(details.building)!='{}'&&details.specials[0].showDeliveryStatus==1">
        <div class="content-title text-sm">交付状态</div>
        <div  class="text-sm mt-2" v-for="(item, key,index) in details.building" :key="index">
            {{key==1?'毛坯交付':'精装交付'}}：{{item}}
        </div>
    </div> -->
    <div class="mb-2 mt-2 pt-4 pb-2.5 px-2.5 bg-white">
      <div class="flex items-baseline">
        <div class="flex-1 font-semibold text-base">{{ details.specials[0].specialName }}总分</div>
        <span v-if="!details.totalScore" class="font-normal text-xs text-gray-300">完成评价自动统计总分</span>
        <div v-else style="color:red" class="text-2xl font-semibold text-danger">{{ details.totalScore }}</div>
        <div v-if="details.totalScore" class="text-sm">分</div>
      </div>
      <div class="table-box mt-2">
        <div class="table">
          <div class="table-item" v-for="(item, index) in tableData" :key="index">
            <div class="t" style="height:3rem">
              <div class="" v-html="item.name"></div>
              <div v-if="item.totalScore!=null">({{item.totalScore}}分)</div>
            </div>
            <div class="t">{{item.score}}</div>
          </div>
        </div>
      </div>
      <div class="mt-4 text-xs text-gray-400" v-if="details.imgUrl.length!=0">项目总览图片</div>
      <div v-if="details.imgUrl.length==0" class="py-4 text-sm" />
      <div v-else class="flex flex-wrap mt-1" :class="details.imgUrl.length==1?'imgType1':(details.imgUrl.length==2||details.imgUrl.length==4?'imgType2':'imgType3')" style="width: 100%;">
        <div v-for="(item, index) in details.imgUrl" :key="index" class="img-item" @click="previewImg(index,details.imgUrl)">
          <img :src="item" @load="imgLoad" @error="imgLoad" class="img">
        </div>
      </div>
    </div>
    
     <!-- 技术标准扣分项(原附减分项) -->   
    <div class="mb-2 pt-4 pb-2.5 px-2.5 bg-white" v-if="!(!!details.additionsList.type3.evaluation&&details.additionsList.type3.evaluation.length==1&&details.additionsList.type3.evaluation[0]=='')||details.additionsList.type2.length>0">
      <template>
        <div class="flex items-baseline">
          <div class="flex-1 text-base font-medium">
            一、技术标准扣分项
          </div>
          <span v-if="details.reduceTotalScore===null" class="font-normal text-xs text-gray-300">完成评价自动统计总分</span>
          <div v-else style="color:#FF9600;font-size:1.2rem" class="text-2xl font-semibold text-danger">{{ details.reduceTotalScore>0?'-':'' }}{{ details.reduceTotalScore }}</div>
          <div v-if="details.reduceTotalScore!==null" class="text-sm">分</div>
        </div>
        <div class="content-box" v-if="!(details.additionsList.type3.evaluation.length==1&&details.additionsList.type3.evaluation[0]=='')">
          <div class="content-title text-sm">概述</div>
          
          <div class="mt-3 text-sm">
                <template v-for="(v, i) in details.additionsList.type3.evaluation">
                  <div class="flex " :key="i+'number'">
                    <div v-if="details.additionsList.type3.evaluation.length>1" class="mr-1">{{numberList1[i]}}</div>
                    <div v-html="v.replace(/(\r\n|\n|\r)/gm , '<br />')" />
                  </div>
                </template>
          </div>
          <!-- <div class="mt-4 text-sm" v-html="item.type0.evaluation?item.type0.evaluation[0].replace(/(\r\n|\n|\r)/gm , '<br />'):''" /> -->
        </div>
        <div v-for="(item, index) in details.additionsList.type2" :key="index" class="content-box">
          <div class="flex items-center">
            <!-- <div class="text-sm">1、</div> -->
            <div v-show="!item.additionalName" class="ml-0.5 text-sm font-medium flex-1">{{ index+1 }}、未选择减分项</div>
            <div v-show="item.additionalName" class="ml-0.5 text-sm font-medium flex-1">{{ index+1 }}、{{ item.additionalName }}</div>
            <div :style="(item.totalScore==3||item.totalScore==10)&&'color:red'">
                    <span class="text-xs">{{item.totalScore>0?'-':''}}{{ item.totalScore }}</span>
                    <span class="text-xs ml-1">分</span>
            </div>
          </div>
          <!-- <div class="mt-4 text-sm" v-html="item.evaluation?item.evaluation.replace(/(\r\n|\n|\r)/gm , '<br />'):''" /> -->
          <div style="display: flex;"  :style="(item.totalScore==3||item.totalScore==10)&&'color:red'" v-for="(val, j) in item.evaluation" class="mt-2 text-sm" :key="j">
              <div v-if="item.evaluation.length>1" class="mr-1">{{numberList1[j]}}</div>
              <div  class="text-sm">{{val}}</div>
          </div>
          <!-- <div class="py-4 text-sm text-gray-400">无</div> -->

          <div class="flex flex-wrap mt-3 mb-2" style="width: 100%;" :class="item.imgUrl.length==1?'imgType1':(item.imgUrl.length==2||item.imgUrl.length==4?'imgType2':'imgType3')">
            <div v-for="(im, ix) in item.imgUrl" :key="ix+'img'" class="img-item" @click="previewImg(ix,item.imgUrl)">
              <img :src="im" @load="imgLoad" @error="imgLoad" class="img">
            </div>
          </div>
        </div>

      </template>
    </div>

    <div v-for="(item, index) in details.list" :key="index" class="mb-2 pt-4 pb-2.5 px-2.5 bg-white">
      <div class="mb-2">
        <div class="flex items-baseline">
          <div
            class="flex-1 text-base font-medium"
          >{{ !(!!details.additionsList.type3.evaluation&&details.additionsList.type3.evaluation.length==1&&details.additionsList.type3.evaluation[0]=='')||details.additionsList.type2.length>0?numberList[index+1]:numberList[index] }}、{{ item.specialName }}得分 (
            <template v-if="item.type0.totalScore!==null">
              <!-- <span class="text-bule" v-show="item.type0.totalScore>=0">{{item.score}}</span> -->
              <span v-show="item.type0.totalScore>=0">{{ item.score }}分</span>
            </template>
            )
          </div>
          <span v-if="item.type0.totalScore===null" class="font-normal text-xs text-gray-300">完成评价自动统计总分</span>
          <div v-else style="color:#FF9600;font-size:1.2rem" class="text-2xl font-semibold text-danger">{{ item.type0.totalScore }}</div>
          <div class="text-sm">分</div>
        </div>
        <div class="content-box">
          <div class="content-title text-sm">总体评价</div>
          <div class="mt-3 text-sm" v-if="item.type0.evaluation&&item.type0.evaluation.length>0">
                <template v-for="(v, i) in item.type0.evaluation">
                  <div class="flex " :key="i+'number'">
                    <div v-if="item.type0.evaluation.length>1" class="mr-1">{{numberList1[i]}}</div>
                    <div v-html="v.replace(/(\r\n|\n|\r)/gm , '<br />')" />
                  </div>
                </template>
          </div>
          <!-- <div class="mt-4 text-sm" v-html="item.type0.evaluation?item.type0.evaluation[0].replace(/(\r\n|\n|\r)/gm , '<br />'):''" /> -->
        </div>

        <div class="content-box">
          <div class="content-title text-sm" v-if="item.type0.imgUrl.length>0">总体评价图片</div>
          <div v-if="item.type0.imgUrl.length!=0" class="flex flex-wrap mt-5 mb-2" style="width: 100%;" :class="item.type0.imgUrl.length==1?'imgType1':(item.type0.imgUrl.length==2||item.type0.imgUrl.length==4?'imgType2':'imgType3')">
            <div v-for="(im, ix) in item.type0.imgUrl" :key="ix" class="img-item" @click="previewImg(ix,item.type0.imgUrl)">
              <img :src="im" @load="imgLoad" @error="imgLoad" class="img">
            </div>
          </div>
          <!-- 分项问题点 -->
          <template v-if="item.dataType==3">
            
            <template v-for="(v, i) in item.type2">
              <!-- <div style="display: flex;" v-if="v.evaluation!=''" class="mt-4 text-sm" :key="i+'-2'">
                {{numberList1[i]}}
                <div v-if="v.evaluation!=''" class="text-sm ml-1" v-html="v.evaluation?v.evaluation.replace(/(\r\n|\n|\r)/gm , '<br />'):''" />
              </div> -->
              <div :key="i+'title2'" class="mt-3.5 text-xs font-medium text-danger">问题点{{i+1}}{{v.negative==1?'【反面案例】':''}}</div>
              <div class="flex items-center mt-2" v-if="v.additionalName" :key="i+'-'+'additionalName'">
                <div class="ml-0.5 text-sm font-medium">{{ v.additionalName }}</div>
              </div>
              <div style="display: flex;" v-for="(val, j) in v.evaluation" class="mt-2 text-sm" :key="i+'-'+j+'text2'">
                    <div v-if="v.evaluation.length>1" class="mr-1">{{numberList1[j]}}</div>
                    <div  class="text-sm ml-1">{{val}}</div>
                </div>
              <div class="flex flex-wrap mt-2 mb-2" :key="i+'-3'" style="width: 100%;" :class="v.imgUrl.length==1?'imgType1':(v.imgUrl.length==2||v.imgUrl.length==4?'imgType2':'imgType3')">
                  <template v-for="(val, j) in v.imgUrl">
                    <div :key="i+'-'+j" class="img-item" @click="previewImg(j,v.imgUrl)">
                      <img :src="val" @load="imgLoad" @error="imgLoad" class="img">
                    </div>
                  </template>
                </div>
            </template>
          </template>
          
          <!-- 分项问题点 -->



          <template v-for="(im,ix) in item.children">
            <div v-if="ix==0&&item.children[item.children.length-1].index!=0" :key="ix+'content-title'" class="content-title text-sm mt-5">分项</div>
            <div :key="ix">
              <div class="flex items-center mt-3">
                  <div class="text-sm flex-1 font-medium " style="font-weight:bold">
                    {{ ix+1 }}、{{ im.specialName }}
                    <template v-if="im.type0.evaluation.length==1">
                      ：<span style="font-weight: normal">{{im.type0.evaluation[0]}}</span>
                    </template>
                    <template v-if="im.type0.evaluation.length==0">
                      ：
                    </template>
                  </div>
              </div>
              <!-- 概述 -->
              <div class="mt-2 mb-5 text-sm" v-show="im.type0.evaluation.length>1" :key="ix+'概述'">
                <template v-for="(v, i) in im.type0.evaluation">
                  <div class="flex" :key="i+'number'">
                    <div v-if="im.type0.evaluation.length>1" class="mr-1">{{numberList1[i]}}</div>
                    <div v-html="v.replace(/(\r\n|\n|\r)/gm , '<br />')" />
                  </div>
                </template>
              </div>
              
              
              <!-- 亮点 -->
              <template v-for="(v, i) in im.type1">
                  <div :key="ix+i+'title1'" class="mt-2 text-xs font-medium text-success"> 亮点{{i+1}}</div>
                  <div style="display: flex;" v-for="(val, j) in v.evaluation" class="mt-2 text-sm" :key="i+'-'+j+'text1'">
                    <div v-if="v.evaluation.length>1" class="mr-1">{{numberList1[j]}}</div>
                    <div  class="text-sm">{{val}}</div>
                  </div>
                  <div class="flex flex-wrap mt-2 mb-2" :key="i+'-33'" style="width: 100%;" :class="v.imgUrl.length==1?'imgType1':(v.imgUrl.length==2||v.imgUrl.length==4?'imgType2':'imgType3')">
                    <template v-for="(val, j) in v.imgUrl">
                      <div :key="i+'-'+j" class="img-item" @click="previewImg(j,v.imgUrl)">
                        <img :src="val" @load="imgLoad" @error="imgLoad" class="img">
                      </div>
                    </template>
                  </div>
              </template>
              <!-- 亮点 -->

              
              <template v-for="(v, i) in im.type2">
                <div :key="ix+i+'title2'" class="mt-3.5 text-xs font-medium text-danger">问题点{{i+1}}{{v.negative==1?'【反面案例】':''}}</div>
                <div style="display: flex;" v-for="(val, j) in v.evaluation" class="mt-2 text-sm" :key="ix+i+'-'+j+'text2'">
                    <div v-if="v.evaluation.length>1" class="mr-1">{{numberList1[j]}}</div>
                    <div  class="text-sm ">{{val}}</div>
                </div>
                <div class="flex flex-wrap mt-2 mb-2" :key="i+'-3'" style="width: 100%;" :class="v.imgUrl.length==1?'imgType1':(v.imgUrl.length==2||v.imgUrl.length==4?'imgType2':'imgType3')">
                    <template v-for="(val, j) in v.imgUrl">
                      <div :key="i+'-'+j" class="img-item" @click="previewImg(j,v.imgUrl)">
                        <img :src="val" @load="imgLoad" @error="imgLoad" class="img">
                      </div>
                    </template>
                  </div>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
   
    <!-- 附加分项 -->
    <div class="mb-2 pt-4 pb-2.5 px-2.5 bg-white" v-if="details.addTotalScore>0">
      <template>
        <div class="flex items-baseline">
          <div class="flex-1 text-base font-medium">
            {{!(!!details.additionsList.type3.evaluation&&details.additionsList.type3.evaluation.length==1&&details.additionsList.type3.evaluation[0]=='')||details.additionsList.type2.length>0?numberList[(details.list?details.list.length+1:0)]:numberList[(details.list?details.list.length:0)]}}
            、加分项
          </div>
          <span v-if="details.addTotalScore===null" class="font-normal text-xs text-gray-300">完成评价自动统计总分</span>
          <div v-else style="color:#FF9600;font-size:1.2rem;" class="text-2xl font-semibold text-danger">{{ details.addTotalScore }}</div>
          <div v-if="details.addTotalScore!==null" class="text-sm">分</div>
        </div>

        <div v-for="(item, index) in details.additionsList.type1" :key="index" class="content-box">
          <div class="flex items-center">
            <!-- <div class="text-sm">1、</div> -->
            <div v-show="!item.additionalName" class="ml-0.5 text-sm font-medium">{{ index+1 }}、未选择加分项</div>
            <div v-show="item.additionalName" class="ml-0.5 text-sm font-medium">{{ index+1 }}、{{ item.additionalName }}</div>
          </div>
          <!-- <div class="mt-4 text-sm" v-html="item.evaluation?item.evaluation.replace(/(\r\n|\n|\r)/gm , '<br />'):''" /> -->
          <div style="display: flex;" v-for="(val, j) in item.evaluation" class="mt-2 text-sm" :key="j">
                    <div v-if="item.evaluation.length>1" class="mr-1">{{numberList1[j]}}</div>
                    <div  class="text-sm">{{val}}</div>
          </div>
          <!-- <div class="py-4 text-sm text-gray-400">无</div> -->

          <div class="flex flex-wrap mt-3 mb-2" style="width: 100%;" :class="item.imgUrl.length==1?'imgType1':(item.imgUrl.length==2||item.imgUrl.length==4?'imgType2':'imgType3')">
            <div v-for="(im, ix) in item.imgUrl" :key="ix" class="img-item" @click="previewImg(ix,item.imgUrl)">
              <img :src="im" @load="imgLoad" @error="imgLoad" class="img">
            </div>
          </div>
        </div>

      </template>
    </div>

  </div>
</template>
<script>
import { ImagePreview } from 'vant'
export default {
  name:'',
  props:['details'],
  data() {
    return {
      htmlTitle: '',
      id: 'report',
      loadImage: 0,
      numberList:{
        0:'一',
        1:'二',
        2:'三',
        3:'四',
        4:'五',
        5:'六',
        6:'七',
        7:'八',
        8:'九',
        9:'十',
        10:'十一',
        11:'十二',
        12:'十三',
        13:'十四',
        14:'十五',
        15:'十六',
        16:'十七',
        17:'十八',
        18:'十九',
        19:'二十',
        20:'二十一',
        21:'二十二',
        22:'二十三',
        23:'二十四',
        24:'二十五',
        25:'二十六',
        26:'二十七',
        27:'二十八',
        28:'二十九',
        29:'三十'
      },
      numberList1:{
        0:'①',
        1:'②',
        2:'③',
        3:'④',
        4:'⑤',
        5:'⑥',
        6:'⑦',
        7:'⑧',
        8:'⑨',
        9:'⑩',
        10:'⑪',
        11:'⑫',
        12:'⑬',
        13:'⑭',
        14:'⑮',
        15:'⑯',
        16:'⑰',
      },
      tableData: [],
    }
  },
  watch:{
        'details.list':{
      handler(val) {
        var totalScore = 0
        this.menu = []
        this.tableData = []
        var topicScore = 0  //题目分数
        for (var item of val) {
          
          var score = 0
          //主项亮点与问题点算分
          if (item.dataType==3) {
            
            for (var im of item.type2) {
              score = this.NumberSub(score, im.totalScore)
            }
          }
          this.tableData.push({
            name: item.specialName,
            totalScore: item.score,
            score: item.type0.totalScore + '分'
          })
          //子项算分
          var index = 0
          for (var im of item.children) {
            if (im.type0.evaluation.length>0){
              index++
            }
            im.index=index
            var s = 0
            for (var v of im.type1) {
              score = this.NumberAdd(score, v.totalScore)
              s = this.NumberAdd(s, v.totalScore)
            }
            for (var v of im.type2) {
              score = this.NumberSub(score, v.totalScore)
              s = this.NumberSub(s, v.totalScore)
            }
            im.type0.totalScore = s
          }
          item.type0.totalScore = this.NumberAdd(item.type0.initScore,score) 
          totalScore = this.NumberAdd(totalScore, item.type0.totalScore)

          //题目计算总分
          if (item.type0.totalScore!=0) {
            topicScore += item.score
          }
        }
        //题目计算总分
        totalScore = this.NumberMul(this.NumberDiv(totalScore, topicScore, 3), 100).toFixed(1)

        this.tableData.unshift({
          name: '技术标准<br>扣分',
          // name: '减分项',
          totalScore: null,
          score: -this.details.reduceTotalScore + '分'
        })
        this.tableData.push({
          name: '加分项',
          totalScore: null,
          score: this.details.addTotalScore + '分'
        })
        this.details.totalScore = this.NumberSub(this.NumberAdd(totalScore,this.details.addTotalScore),this.details.reduceTotalScore)
        this.$forceUpdate()
      },
      deep:true
    },
    'details.additionsList.type1':{
      handler(arr) {
        var totalScore = 0
        for (var item of arr) {
          totalScore = this.NumberAdd(totalScore, item.totalScore)
        }
        this.details.addTotalScore = totalScore
        for (var item of this.details.list) {
          var score = 0
          for (var im of item.children) {
            score = this.NumberSub(this.NumberAdd(score, im.type1.totalScore),im.type2.totalScore)
          }
          item.type0.totalScore = this.NumberAdd(item.type0.initScore,score)
          totalScore = this.NumberAdd(totalScore, item.type0.totalScore)
        }
        this.details.totalScore = totalScore
      },
      deep:true,
      immediate: true
    },
    'details.additionsList.type2':{
      handler(arr) {
        var totalScore = 0
        for (var item of arr) {
          totalScore = this.NumberAdd(totalScore, item.totalScore)
        }
        this.details.reduceTotalScore = totalScore
        totalScore = -totalScore
        for (var item of this.details.list) {
          var score = 0
          for (var im of item.children) {
            score = this.NumberSub(this.NumberAdd(score, im.type1.totalScore),im.type2.totalScore)
          }
          item.type0.totalScore = this.NumberAdd(item.type0.initScore,score)
          totalScore = this.NumberAdd(totalScore, item.type0.totalScore)
        }
        this.details.totalScore = totalScore
      },
      deep:true,
      immediate: true
    }
  },
  methods:{
    previewImg(index, images) {
      ImagePreview({
        images:images,
        startPosition:index
      })
    },
    imgLoad() {
      if (this.$route.name!='report') { return false }
      var that = this
      this.$toast.loading({
        message: '图片加载中'+ that.loadImage +'/' +this.details.loadNum,
        forbidClick: true,
        duration: 0
      })
      ++that.loadImage   // 设置的变量名
      console.log(that.loadImage)
      if (that.loadImage === this.details.loadNum) {  // 这个数据为 页面中图片的总个数
        this.$getPdf(`${this.details.areaName}区域${this.details.cityName}城市${this.details.projectName}项目`)
        this.$toast.clear()
      }
    },
  }
}
</script>
<style>
.imgType1 .img-item{
  width: calc(100vw / 1.3 - (8px * 1));
  height: calc(100vw / 1.3 - (8px * 1));
}
.imgType2 .img-item{
  width: calc(100vw / 2.3 - (8px * 2));
  height: calc(100vw / 2.3 - (8px * 2));
}
.imgType3 .img-item{
  width: calc(100vw / 3.2 - (8px * 3));
  height: calc(100vw / 3.2 - (8px * 3));
}
.img-item {
  /* width: calc((100vw - (0.625rem * 2) - (8px * 2)) / 4);
  height: calc((100vw - (0.625rem * 2) - (8px * 2)) / 4); */
  margin-right: 8px;
  margin-top: 8px;
  overflow: hidden;
}
/* .img-item:nth-child(3n + 1) {
  margin-left: 0;
}
.img-item:nth-child(n + 4) {
  margin-top: 8px;
} */
.img-item .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.text-bule{
  color: #6396fe;
}
.text-success{
  color:#0C8846
}
.text-danger{
  color:#FF3E4C;
}
.content-box{
  margin-top:16px;
}
.content-box+.content-box{
  margin-top:20px;
}
.label-num{
  position: relative;
  width:18px;
  height:18px;
  border-radius:50%;
  border:1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
}
.label-num:after{
  font-size:12px;
}
.num-1:after{content:"1"}
.num-2:after{content:"2"}
.num-3:after{content:"3"}
.num-4:after{content:"4"}
.num-5:after{content:"5"}
.num-6:after{content:"6"}
.num-7:after{content:"7"}
.num-8:after{content:"8"}
.num-9:after{content:"9"}
.num-10:after{content:"10"}
.num-11:after{content:"11"}
.num-12:after{content:"12"}
.num-13:after{content:"13"}
.num-14:after{content:"14"}
.num-15:after{content:"15"}
.num-16:after{content:"16"}
.num-17:after{content:"17"}
.num-18:after{content:"18"}
.num-19:after{content:"19"}
.num-20:after{content:"20"}
.table-box{
  max-width: 100%;
  overflow-x: auto;
  
  font-size: 12px;
  color: #909399;
  font-weight: 500;
}
.table-box::-webkit-scrollbar{
  width: 0px;
}
.table-box::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0);
  border-radius: 0;
  background   : transparent;
  }
.table-box::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 0;
  box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0);
  background   : transparent;
}
.table{
  display: flex;
  border-left: 1px solid #EBEEF5;
  border-right: 0;
}
.table-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #EBEEF5;
}
.table-item .t{
  width: 100%;
  text-align: center;
  white-space: nowrap;
  padding: 8px 12px;
  border-top: 1px solid #EBEEF5;
}
.table-item .t:nth-child(2){
  border-top: 1px solid #EBEEF5;
  border-bottom: 1px solid #EBEEF5;
}
</style>
