<template>
    <div class="report">
        <van-sticky style="width:100%">
        <div class="submit-btn-box border-b">
            <div class="submit-btn1" @click="$getPdf(htmlTitle)">导<div style="width:20px;height:10px"></div>出</div>
        </div>
        </van-sticky>
        <div
            class="report-item"
            id="report"
            v-if="scoreDetails.specials && scoreDetails.specials.length > 0"
        >
            <div class="title">{{scoreDetails.areaName}}区域{{scoreDetails.cityName}}城市{{scoreDetails.projectName}}项目</div>
            <div class="subtitle">{{scoreDetails.acceptanceType==2?'大盘区验收简报':'展示区验收简报'}}</div>
            
            <div class="flex text-base font-medium text-gray-900 mt-8">
                【检查时间】
                <div class="mt-0.5 text-sm text-gray-600"> {{scoreDetails.acceptanceDate.slice(0,10)}}</div>
            </div>
            <div class="flex text-base font-medium text-gray-900 mt-8" v-if="scoreDetails.acceptanceType==2">
                【交付状态】
                <div>
                    <template v-for="(item, key ,index) in details.project.building?JSON.parse(details.project.building):{}">
                        <div class="mt-0.5 text-sm text-gray-600" :key="index"> {{key==1?'毛坯交付':'精装交付'}}：{{item}}</div>
                    </template>
                </div>
            </div>
            <div class="flex text-base font-medium text-gray-900 mt-8" >
                【一线负责人】
            </div>
            <div class="ml-2" v-for="(item, key ,index) in details.projectInfo[0].type===0?details.projectInfo[0].projectData:details.projectInfo[1].projectData" :key="index+'-'">
                <div v-for="(im, k, ix) in item" class="mt-2" :key="index+'--'+ ix">
                  <div class="text-base font-medium text-gray-900">{{k}}：{{im}}</div>
                </div>
            </div>
            <div class="flex text-base font-medium text-gray-900 mt-8">
                【供应方信息】
            </div>
            <div class="ml-2" v-for="(item, key ,index) in details.projectInfo[0].type===1?details.projectInfo[0].projectData:details.projectInfo[1].projectData" :key="index">
                <div class="mt-4 text-base text-gray-600" :key="index">{{key}}：</div>
                <div v-for="(im, k, ix) in item" class="mt-2" :key="ix+'-'+ index">
                  <div class="text-base font-medium text-gray-900">{{numberList1[ix]}}【{{k}}】</div>
                  <div class="mt-1.5 text-sm text-gray-600" v-for="(v, j, i) in im" :key="i">{{j}}：{{v}}</div>
                </div>
            </div>
            <div class="mt-2">
                <score :details="scoreDetails" />
            </div>
        </div>
    </div>
</template>

<script>
import { sticky } from 'vant'
import score from "components/score";
import { getReport } from "../../api";

export default {
    components: {
        score: score,
        [sticky.name]:sticky,
    },
    props: {},
    data() {
        return {
            htmlTitle: '',
            id: 'report',
            scoreDetails: {},
            details: {},
            numberList1:{
                0:'①',
                1:'②',
                2:'③',
                3:'④',
                4:'⑤',
                5:'⑥',
                6:'⑦',
                7:'⑧',
                8:'⑨',
                9:'⑩',
                10:'⑪',
                11:'⑫',
                12:'⑬',
                13:'⑭',
                14:'⑮',
                15:'⑯',
                16:'⑰',
            },
            url: ''
        };
    },
    watch: {},
    computed: {},
    methods: {
        
        getReport() {
            getReport(this.$route.params.id).then((res) => {
                var data = res.data.data
                for(var item of data.projectInfo) {
                    item.projectData = JSON.parse(item.projectData)
                }
                
                this.details = data
                this.detailsFun(data.detail);
            });
        },
        detailsFun(data) {
            data.building = data.building ? JSON.parse(data.building) : {};
            data.imgUrl = data.imgUrl ? data.imgUrl.split(",") : [];
            var imgUrlArr = [];
            let num = 0
            for (var im of data.imgUrl) {
                imgUrlArr.push(
                    im + "?x-oss-process=style/compress&" + new Date().getTime()
                );
            }
            num += imgUrlArr.length
            data.imgUrl = imgUrlArr;
            // 判断是否是修改
            if (data.id) {
                var myDate = new Date(data.acceptanceDate.replace(/\-/g, "/"));
                var m = myDate.getMonth() + 1;
                var d = myDate.getDate();
                data.acceptanceDate =
                    myDate.getFullYear() +
                    "-" +
                    (m < 10 ? "0" + m : m) +
                    "-" +
                    (d < 10 ? "0" + d : d) +
                    " 00:00:00";
                this.screen_date =
                    myDate.getFullYear() + " 年 " + m + " 月 " + d + " 日";
            } else {
                var myDate = new Date();
                var m = myDate.getMonth() + 1;
                var d = myDate.getDate();
                data.acceptanceDate =
                    myDate.getFullYear() +
                    "-" +
                    (m < 10 ? "0" + m : m) +
                    "-" +
                    (d < 10 ? "0" + d : d) +
                    " 00:00:00";
                this.screen_date =
                    myDate.getFullYear() + " 年 " + m + " 月 " + d + " 日";
            }
            var specials = data.specials;
            for (var item of specials) {
                var obj = {
                    evaluation: "",
                    imgUrl: [],
                    initScore: null,
                    isType: 0,
                    specialId: 0,
                    totalScore: null,
                    itemType: 0,
                };
                item.itemType = 0;
                item.imgUrl = item.imgUrl ? item.imgUrl.split(",") : [];
                var imgUrlArr1 = [];
                for (var im of item.imgUrl) {
                    imgUrlArr1.push(
                        im +
                            "?x-oss-process=style/compress&" +
                            new Date().getTime()
                    );
                }
                num += imgUrlArr1.length
                item.imgUrl = imgUrlArr1;
                obj.specialId = item.id;
                item["type0"] = JSON.parse(JSON.stringify(obj));

                if (item.parentId != data.specialId) {
                    item["type1"] = [];
                    item["type2"] = [];
                }
                // 分项问题点
                if (item.dataType == 3) {
                    item["type2"] = [];
                }
                // 分项问题点

                for (var im of data.contents ? data.contents : []) {
                    if (item.id == im.specialId) {
                        var str = "type" + im.isType;
                        im.imgUrl = im.imgUrl ? im.imgUrl.split(",") : [];
                        var imgUrlArr3 = [];
                        for (var v of im.imgUrl) {
                            imgUrlArr3.push(
                                v +
                                    "?x-oss-process=style/compress&" +
                                    new Date().getTime()
                            );
                        }
                        num += imgUrlArr3.length
                        im.imgUrl = imgUrlArr3;
                        im.evaluation =
                            im.evaluation != ""
                                ? im.evaluation.split("%s%")
                                : [];
                        if (im.isType == 0) {
                            item[str] = im;
                        } else {
                            // im.evaluation = im.evaluation.replace(/(%s%)/gm , '<br />')
                            if (!item.imgs) {
                                item.imgs = [];
                            }
                            item.imgs = item.imgs.concat(im.imgUrl);
                            item[str].push(im);
                        }
                    }
                }
            }
            var list = this.GetTree(specials, data.specialId)[0].children;

            data.list = list;
            data.addTotalScore = null;
            data.reduceTotalScore = null;
            var type1Arr = [];
            var type2Arr = [];
            var type3Obj = {
                evaluation: [''],
                imgUrl: [],
                initScore: null,
                isType: 3,
                negative: 0,
                specialId: 0,
                totalScore: null,
                itemType: 1,
            }
            data.additions = data.additions ? data.additions : [];

            for (var item of data.additions) {
                var imgUrlArr2 = [];
                item.imgUrl = item.imgUrl ? item.imgUrl.split(",") : [];
                for (var im of item.imgUrl) {
                    imgUrlArr2.push(
                        im +
                            "?x-oss-process=style/compress&" +
                            new Date().getTime()
                    );
                }
                num += imgUrlArr2.length
                item.imgUrl = imgUrlArr2;
                item.evaluation = item.evaluation.split("%s%");
                if (item.isType == 1) {
                    type1Arr.push(item)
                } else if (item.isType == 2) {
                    type2Arr.push(item)
                } else if (item.isType == 3) {
                    type3Obj = item
                }
            }

            data.additionsList = {
                type1: type1Arr,
                type2: type2Arr,
                type3: type3Obj,
            };
            data.loadNum = num
            this.scoreDetails = data;
            this.htmlTitle =  `${data.areaName}区域${data.cityName}城市${data.projectName}项目`
        },
        GetTree(data, id) {
            var list = data;
            var top = [];
            var sub = [];
            var tempObj = {};
            list.forEach(function (item) {
                if (item.id == id) {
                    // 顶级分类
                    item.index = top.length;
                    top.push(item);
                } else {
                    // item.index = sub.length
                    sub.push(item); // 其他分类
                }
                item.children = []; // 默然添加children属性

                tempObj[item.id] = item; // 用当前分类的id做key，存储在tempObj中
            });
            sub.forEach(function (item) {
                // 取父级
                var parent = tempObj[item.parentId] || { children: [] };
                // 把当前分类加入到父级的children中
                item.index = parent.children.length;
                parent.children.push(item);
            });
            return top;
        },
    },
    created() {
        this.getReport();
    },
    mounted() {},
};
</script>
<style scoped>
.report .title{
    width: 100%;
    text-align: center;
    font-size: 36px;
    line-height: 46px;
    margin-top: 100px;
    padding:  0 10%;
    box-sizing: border-box;
}
.report .subtitle{
    width: 100%;
    text-align: center;
    font-size: 26px;
    line-height: 30px;
    margin-top: 20px;
    margin-bottom: 70px;
    padding:  0 10%;
    box-sizing: border-box;
}
.report{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.report-item{
    width: 100%;
    max-width: 800px;
    background-color: #fff;
}
.report>>>.img-item{
    width: 80%!important;
    height: auto!important;
}
.submit-btn-box{
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: #fff;
  z-index: 1000;
}
.submit-btn1 {
  display: flex;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  line-height: 30px;
  background: linear-gradient(to bottom, #7fa9ff, #6497ff);
  box-shadow: 2px 3px 11px 0px rgba(255, 255, 255, 0.26) inset,
    -1px -1px 9px 0px rgba(90, 140, 243, 0.67) inset;
  border-radius: 5px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  box-sizing: border-box;
  text-align: center;
  box-sizing: border-box;
  margin: 0 4px;
}
</style>